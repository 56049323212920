<div class="flex w-100 h-100 overflow-hidden">
    <div class="flex items-left welcome-wrapper bg-login p-2">
        <img class="logo-mini" src="./assets/logo-mini.png">
        <div class="my-10"></div>
        <ul class="p-0 flex flex-col justify-content-between flex-1 mb-5 section-bullets">
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">PIES AI – AI Assistant</h6>
                        <p class="message">
                            Our platform now features a conversational AI assistant. This tool is not only capable
                            of engaging in dialogue but can also assist in developing everything from new features
                            to complete applications.
                        </p>
                    </div>
                </div>
            </li>
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">Code Export</h6>
                        <p class="message">
                            We understand the importance of intellectual property protection. Our new code export
                            feature allows you to safeguard your IP by exporting fully functional code in your
                            preferred programming language.
                        </p>
                    </div>
                </div>
            </li>
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">Custom Widgets</h6>
                        <p class="message">
                            Enhance your toolkit by creating, sharing, and even monetizing your own widgets. This
                            feature offers unprecedented flexibility and creative freedom within our ecosystem.
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <div class="flex flex-col mb-3 section-banner justify-end">
            <div class="flex items-center mb-3">
                <h2 class="text-2xl text-light typewriter mr-1"></h2>
                <h2 class="text-2xl text-light">Development Platform</h2>
            </div>
            <p class="message-lg">No-code for speed, low-code for flexibility and code export for independence</p>
        </div>
        <img class="logo-floating mr-2" src="./assets/logo-mini.png" alt="Application Background Logo" />
    </div>
    <div class="login-wrapper flex flex-col justify-center items-center p-3">
        <div class="login-card flex flex-col">
            @if (this.status !== Status.success && this.status !== Status.error && this.status !== Status.conflict ) {
            <div class="p-16">
                <h4 class="text-2xl font-medium mb-0">Create your account</h4>
                <div class="flex items-center my-6">
                    <div class="separator mr-2"></div>
                    <div class="separator separator-sm mr-2"></div>
                </div>
                <form [formGroup]="registrationFormGroup" (ngSubmit)="register()" class="space-y-4 flex flex-col">
                    <div>
                        <label for="name" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Name
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <input maxlength="255" formControlName="name" autocomplete="off" id="name" type="text"
                            class="input" placeholder="John Doe" required>

                        @if (registrationFormGroup.controls['name'].touched
                        && registrationFormGroup.controls['name'].errors?.['required']){
                        <span class="text-xs font-medium text-red-500">This field is required</span>
                        } @else if(registrationFormGroup.controls['name'].touched &&
                        registrationFormGroup.controls['name'].errors?.['minlength']){
                        <span class="text-xs font-medium text-red-500">Name should be atleast 3 characters</span>
                        }

                    </div>
                    <div>
                        <label for="email" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Email
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <input maxlength="255" formControlName="email" autocomplete="email" id="email" type="email"
                            class="input" placeholder="someone@example.com" required>
                        @if (registrationFormGroup.controls['name'].touched
                        &&registrationFormGroup.controls['email'].errors?.['required']){
                        <span class="text-xs font-medium text-buttonDangerColor">This field is required</span>
                        } @else if(registrationFormGroup.controls['email'].touched &&
                        registrationFormGroup.controls['email'].errors?.['email']){
                        <span class="text-xs font-medium text-buttonDangerColor">Email is invalid</span>
                        }
                    </div>
                    <div>
                        <label for="phone" class="block mb-2 text-xs font-medium text-fontPrimary">Phone</label>
                        <input maxlength="15" minlength="7" formControlName="phone" autocomplete="mobile" id="phone" 
                            type="tel" class="input" placeholder="" appIntlTelInput (change)="onTelInputErr($event);" (inputRef)="telInputRef = $event;">
                        @if (registrationFormGroup.controls['phone'].touched
                        && registrationFormGroup.controls['phone'].errors?.['pattern']){
                        <span class="text-xs font-medium text-buttonDangerColor">Phone number is invalid.</span>
                        }@else if (registrationFormGroup.controls['phone'].errors?.['numberInvalid']) {
                        <span class="text-xs font-medium text-buttonDangerColor">Phone number is invalid.</span>
                        }
                    </div>
                    <!-- <div>
                        <label for="company" class="block mb-2 text-xs font-medium text-fontPrimary">Company
                            Name</label>
                        <input maxlength="255" formControlName="company" autocomplete="organization" id="company"
                            type="text" class="input" placeholder="Acme Inc.">
                    </div> -->
                    <div>
                        <label for="country" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Country
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <select formControlName="country" autocomplete="country-name" id="country" class="input">
                            @for (country of countries; track country.name) {
                            <option [value]="country.code">{{country.name}}</option>
                            }
                        </select>
                        @if (registrationFormGroup.controls['country'].touched
                        && registrationFormGroup.controls['country'].errors?.['required']){
                        <span class="text-xs font-medium text-buttonDangerColor">This field is required</span>
                        }
                    </div>
                    <div class="flex justify-start items-center">
                        <p class="text-sm mt-1 mb-2">By creating your account, you agree to our <a
                                href="https://docs.pies.io/pies-studio-developer-guide/v0.98/terms-conditions"
                                target="_blank" class="cursor-pointer no-underline text-buttonAlternate">Terms of
                                Use</a> and <a class="cursor-pointer no-underline text-buttonAlternate"
                                href="https://docs.pies.io/pies-studio-developer-guide/v0.98/privacy-policy"
                                target="_blank">Privacy Policy</a></p>
                    </div>
                    <button [disabled]="registrationFormGroup.invalid" type="submit"
                        class="btn btn-primary w-full flex justify-center">
                        @if (status !== Status.connecting) {
                        Submit
                        } @else {
                        <div role="status">
                            <mat-spinner mode="indeterminate" [diameter]="25"></mat-spinner>
                            <span class="sr-only">Loading...</span>
                        </div>
                        }
                    </button>

                    <p class="text-start text-sm mt-4">
                        Already have an account? <a [routerLink]="['/auth/login']"
                            class="no-underline text-buttonAlternate">Log In</a>
                    </p>
                </form>
            </div>
            } @else if(this.status === Status.error) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    <h4 class="text-2xl font-medium">Error</h4>
                </div>
                <p class="text-base mb-10">Something went wrong while creating your account. Please try
                    again after some time.</p>
                <p class="text-base">
                    Already set up your account? <a [routerLink]="['/auth/login']"
                        class="no-underline text-buttonAlternate ">Log In</a>
                </p>
            </div>
            }@else if(this.status === Status.conflict) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    <h4 class="text-2xl font-medium">Error</h4>
                </div>
                <p class="text-base mb-10">This email is already registered. Please try with a different email or log
                    in.</p>
                <p class="text-base ">
                    Already set up your account? <a [routerLink]="['/auth/login']"
                        class="no-underline text-buttonAlternate">Log In</a>
                </p>
            </div>
            } @else if (this.status === Status.success) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    <h4 class="text-2xl font-medium">Success</h4>
                </div>
                <p class="text-base mb-10">
                    Your registration was successful. We have emailed you instructions to about how to set your
                    password, please check your inbox for more details. <br />
                </p>
                <p class="text-base ">
                    Already set your password? <a [routerLink]="['/auth/login']"
                        class="no-underline text-buttonAlternate">Log In</a>
                </p>
            </div>
            }
        </div>
    </div>
</div>

<!-- <div class="flex flex-col flex-1 justify-start md:justify-center items-start md:items-center bg-secondary p-5 md:p-0">
    <img class="mb-5 h-10 w-auto" src="./assets/images/logo-full.png"
        alt="PIES Studio">
    <div class="flex justify-center items-center mb-6">
        <h1 class="text-fontPrimary text-xl md:text-2xl font-medium typewriter"></h1>
        <h1 class="text-fontPrimary text-xl md:text-2xl font-medium">Development Platform</h1>
    </div>
    <div class="w-full md:w-auto md:min-w-96 bg-secondary rounded-lg border-0 md:border mb-5">
        <div class="p-0 md:p-6 space-y-4 md:space-y-6 sm:p-8">
            <h2 class="text-lg font-medium leading-tight xl:text-xl text-fontPrimary">
                Create an account
            </h2>
            
        </div>
    </div>
</div> -->