import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Alert, AlertType } from '../../../model/alert';
import { AlertService } from '../../../service/alert.service';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent implements OnInit, OnDestroy {

  @Input() id: string = 'default';
  @Input() fade = true;

  alerts: Alert[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private alertService: AlertService,
    private routers: Router,
  ) { }

  ngOnInit(): void {
    this.alertService.onAlert(this.id).subscribe(alert => {
      if (!alert.message) {
        this.alerts = [];
        return;
      }

      this.alerts.push(alert);
      this.changeDetector.detectChanges();

      if (!alert.keepOpen) {
        setTimeout(() => this.removeAlert(alert), 4000);
      }
    });

    this.routers.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    })
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      const a = this.alerts.find(x => x === alert);

      if (a !== undefined) a.fade = true;

      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert);
      }, 250);
    } else {
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }


  ngOnDestroy(): void {

  }

  cssClass(alert: Alert) {
    if (!alert) return;

    const classes = [];

    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-error',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.LockRequest]: 'alert alert-lock-req',
      [AlertType.Warning]: 'alert alert-warning'
    }

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  clearAlert(id: string) {
    this.alertService.clear(id);
  }
}
