import { environment } from "./environment";

export const url = {
    register: `${environment.backend}/auth/register`,
    token: `${environment.backend}/token`,
    organization: {
        get: '/api/organization',
        getInfo: '/license',
        validateLicense: '/license/validate',
        activateLicense: '/license/activate',
        getBranding: "/organization/getBranding",
        updateBranding: "/api/organization/organization/updateBranding",
        users: '/api/organization/users',
        deleteUser: '/api/organization/users/delete',
    },
    adminUser: {
        addUser: '/api/v1/user/add',
        deleteUser: '/api/v1/user/delete',
        updateUser: '/api/v1/user/update',
        getAllUser: '/api/v1/user/all',
        getUser: '/api/v1/user/',
        activateUser: '/api/v1/user/activate',
        deactivateUser: '/api/v1/user/deactivate',
        inviteUser: '/api/v1/user/invite',
        updateUserProfile: '/api/v1/user/updateuserprofile',
        changePassword: '/api/v1/user/changepassword',
        fetchFromAD: '/api/v1/user/ad/getall',
        addFromAD: '/api/v1/user/ad/addall',
        getAllRole: '/api/v1/roles/all',
        search: '/api/v1/user/search',
    },
    auth: {
        login: '/login',
        forgotPassword: `${environment.backend}/auth/password/forgot`,
        updatePassword: '/updatepassword',
        refreshToken: '/refreshtoken',
        updateSettings: '/api/v1/user/updatesettings',
        verifyToken: `${environment.backend}/auth/password/verify`,
        resetPassword: `${environment.backend}/auth/password/reset`,
    },
    license: {
        activateCommnunity: '/api/license/activate/community',
        generateSecret: '/api/license/secret',
        updateRedirect: '/api/license/updateRedirect',
        downloadDockerCompose: '/api/license/download/dockerCompose',
        all: '/api/license/all',
        status: '/api/license/status',
        sso: '/api/license/sso',
    },
    request: {
        list: '/api/request/all',
        create: '/api/request',
        update: '/api/request',
        delete: '/api/request',
        get: '/api/request',
    },
    admin: {
        licenses: '/api/license/all',
        license: '/api/license',
        org: '/api/organization',
        users: '/api/license/users',
    },
    billing: {
        checkout: '/api/billing/checkout',
        plan: {
            all: '/api/billing/plan/all'
        },
        transaction: {
            all: '/api/billing/transaction/all'
        },
        subscription: {
            get: '/api/billing/subscription',
            costs: '/api/billing/subscription/costs',
            methods: '/api/billing/payment_method/all',
            cancel: '/api/billing/subscription/cancel',
            restore: '/api/billing/subscription/restore',
            update: '/api/billing/subscription/update',
            cycle: '/api/billing/subscription/cycle',
        },
    },
    environment: {
        all: '/api/environment/all',
        get: '/api/environment',
        create: '/api/environment',
        purchase: '/api/environment/purchase',
        delete: '/api/environment/delete',
        restore: '/api/environment/restore',
        shutdown: '/api/environment/shutdown',
        activate: '/api/environment/activate',
        restart: '/api/environment/restart',
        locations: '/api/environment/locations',
        specs: '/api/environment/vm/info',
        access: {
            add: '/api/environment/access/add',
            remove: '/api/environment/access/remove',
        }
    },
};